import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loading';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import OTPInput from '../../atoms/OTPInput';
import Paragraph from '../../atoms/Paragraph';
import Alert from './../../molecules/Alert';
import { sendOtp, sendOtpForLogin, sendOtpForLoginWithEmail } from './../../services/user.service';
import { Context } from '../../constant/_context'
import * as moengage from "services/moengage.service";
import mo_engage_onboarding from "../../assets/mo_engage/onboarding";
import { OnClickProfileMobileNumberEditProceed, OnClickProfileMobileNumberEditResendOTP, OnClickProfileMobileNumberEditChangeNumber } from "../../components/ProfileCard/ProfileMoengage";
import Card from 'atoms/Card';
import { moEngageLoginPageChangeNumber, moEngageLoginPageResendOTP, moEngageLoginPageSubmitOTP } from '@/components/SignUp/LoginSignUpMonengage';
import { gaEngageLoginPageChangeNumber, gaEngageLoginPageResendOTP, gaEngageLoginPageSubmitOTP, gaIndividualRegisterSubmitOtpClick } from '@/components/SignUp/LoginSignUpGA';
import Loading from 'components/Loader/Loading';
import { LOGIN_USER_TYPE } from "constant/login_signup";
import { useRouter } from "next/router";


const OtpVerification = ({ className, isModalOpen, handleModalStateChange, apiData, creatUser, mobileNo, email, type, loginUser, handleLoginScreen,
    alertMsg, setAlertMsg, handlerModalClose, changeLabel, isLoading, setIsLoading, activeTab, isPatient }) => {
    const [otpValue, setOtpValue] = useState("");
    const [isDisableProceedBtn, setIsDisableProceedBtn] = useState(true);
    const [countdown, setCountdown] = useState(30);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const entity = mo_engage_onboarding.entity;
    const router = useRouter();

    useEffect(() => {
        let timer;
        if (isResendDisabled) {
            timer = setInterval(() => {
                setCountdown(prev => {
                    if (prev === 1) {
                        clearInterval(timer);
                        setIsResendDisabled(false);
                        return 30;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => { clearInterval(timer) };
    }, [isResendDisabled]);

    const hideEmail = (email) => {
        var hiddenEmail = "";
        for (let i = 0; i < email.length; i++) {
            if (i > 2 && i < email.indexOf("@")) {
                hiddenEmail += "*";
            } else {
                hiddenEmail += email[i];
            }
        }
        return hiddenEmail
    }
    const hidePhoneNumber = (mobile) => {
        if (mobile) return mobile.replace(mobile.substring(0, 6), 'XXXXXX');
    }
    const backToRegScreen = () => {
        moengage.trackEvent(entity.onboarding_change_number_signup.event_name, {})
        setOtpValue("")
        handleModalStateChange(false)
    }


    const handleResendOtp = async () => {
        setIsResendDisabled(true);
        moengage.trackEvent(entity.onboarding_resend_OTP_signup.event_name, {})
        let mobilePattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/
        let emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (type == 'login') {
            if (mobileNo.match(mobilePattern)) {
                setIsLoading(true);
                const response = await sendOtpForLogin({
                    mobileNumber: mobileNo,
                    country_code: "91",
                    context: Context.account_login_otp,
                    userType: router.query.type == 'individual' ? 1 : null,
                })
                if (response?.data?.message === "Successfully sent Otp!") {
                    setAlertMsg({ message: "Resent OTP successfully!", type: "success" });
                } else {
                    setAlertMsg({ type: "error", message: "Please verify your mobile and try again" })
                }
                setIsLoading(false);
            } else if (email.toLowerCase().match(emailPattern)) {
                const response = await sendOtpForLoginWithEmail({
                    email: email
                })
                if (response?.data?.message === "Successfully sent Otp!") {
                    setAlertMsg({ message: "Resent OTP successfully!", type: "success" });
                } else {
                    setAlertMsg({ type: "error", message: "Please verify your email and try again" })
                }
            }
        }
        if (type == 'reg') {
            const response = await sendOtp({
                mobileNumber: apiData?.mobileNumber,
                country_code: apiData?.country_code,
                email: apiData?.userData?.email,
                full_name: apiData?.userData?.full_name
            });
            if (response?.data?.message === "Successfully sent Otp!") {
                setAlertMsg({ message: "Resent OTP successfully!", type: "success" });
            } else {
                setAlertMsg({ message: response?.data?.message, type: "error" });
            }
        }
    }

    useEffect(() => {
        if (otpValue.length === 4) {
            setIsDisableProceedBtn(false)
        } else {
            setIsDisableProceedBtn(true)
        }
    }, [otpValue.length])

    return (
        <div className={`md:w-full flex-shrink-0 w-full relative lg:pr-8 md:pr-8`}>
            {isLoading && <Loading type='layer' />}
            <Card type='card--regular' className="relative bg-basic-whitePure md:mt-7.5 lg:mb-5  w-full lg:max-w-[550px] shadow-none md:shadow-sm rounded-none md:rounded-2xl-0.5 overflow-hidden">
                {alertMsg?.message && (
                    <Alert type={alertMsg.type} effect="slideDown" resetAlertCallback={() => setAlertMsg(null)}>
                        {alertMsg.message}
                    </Alert>
                )}
                <div className="pt-6 pb-6 md:pb-5 px-6 py-5 max-w-[420px] mx-auto lg:mt-2 md:mt-2">
                    <Heading type="h3" className='text-center'>Verification</Heading>

                    <div className="py-6 xs:py-10 sm:py-12 lg:py-8 mx-auto">
                        <Paragraph className="mb-8 lg:mb-12 text-center">
                            We have sent a 4 digit OTP via SMS and WhatsApp on  {mobileNo && "+91 " + hidePhoneNumber(mobileNo)}
                        </Paragraph>
                        {isLoading && <Loader />}
                        <OTPInput
                            className="flex justify-center"
                            length={4}
                            onChange={(otp) => { setOtpValue(otp) }}
                            otpValue={otpValue}
                        />

                        
                        <div className={`flex mt-5 ${type === 'forget' ? 'justify-center' : 'justify-between'}`}>
                            <Button
                                variant="Link" size="medium" fontSize='overline'
                                onClick={() => { setOtpValue(''); handleResendOtp(); gaEngageLoginPageResendOTP(); moEngageLoginPageResendOTP(); }}
                                dataAutomation='resend-otp'
                                disabled={isResendDisabled}
                                className={isResendDisabled?"!bg-basic-whitePure border-none":''}
                                >
                                {isResendDisabled ? `Re-send OTP in ${countdown}s` : 'Re-send OTP'}
                            </Button>
                            {type !== 'forget' && (
                                <Button variant="Link" fontSize='overline' size="medium"
                                    onClick={() => OnClickProfileMobileNumberEditChangeNumber(() => {
                                        type === "reg" ? backToRegScreen() : handleLoginScreen(true); setOtpValue('');
                                        gaEngageLoginPageChangeNumber();
                                        moEngageLoginPageChangeNumber();
                                    })}
                                    dataAutomation={changeLabel ? `change-${changeLabel.toLowerCase()}` : "change-number"} >
                                    Change {changeLabel ? changeLabel : "Number"}
                                </Button>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-center lg:mt-5 md:mt-5 lg:mb-5 md:mb-5">
                        <Button
                            className="w-200px"
                            size="medium"
                            variant="Primary"
                            onClick={() => OnClickProfileMobileNumberEditProceed(() => {
                                setIsDisableProceedBtn(true);
                                setOtpValue('');
                                type === "reg" ? creatUser(otpValue) : loginUser(otpValue);
                                router.query.type == 'individual' ? gaIndividualRegisterSubmitOtpClick() : gaEngageLoginPageSubmitOTP();
                                moEngageLoginPageSubmitOTP();
                            })}
                            disabled={isDisableProceedBtn}
                            dataAutomation='otp-proceed'
                        >
                            Proceed
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    )
}

OtpVerification.propTypes = {
    className: PropTypes.string,
    isModalOpen: PropTypes.bool,
    handleModalStateChange: PropTypes.func,
    creatUser: PropTypes.func,
    mobileNo: PropTypes.string,
    email: PropTypes.string,
}

export default OtpVerification;
