import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from "next/router";

import SignUpLeftSide from '@/components/SignUp/SignUpLeftSide';
import { getRedirectUrl, setAuth, setRedirectUrl } from "services/identity.service";
import { creatUserApi, LoginWithMobileOtp, LoginWithEmailOtp, addInterestedUser } from "services/user.service.js"
import ModalLogin from 'molecules/Modals/ModalLogin';
import FormSignUp from 'molecules/v2/FormSignUp';
import ForgetPassword from 'components/SignUp/ForgetPassword';
import { addNirogMoney } from 'services/nirogmoney.service';
import { NirogMoneyContext } from 'constant/nirog_money/nirog_money_context_types';
import ModalForDeactivatedUser from 'molecules/Modals/ModalForDeactivatedUserLogin';
const appId = process.env.NEXT_PUBLIC_APP_ID;
import * as moengage from "services/moengage.service";
import mo_engage_onboarding from "assets/mo_engage/onboarding";
import { useParticipateOlympiadContext } from 'components/context/signUpContext.js';
import mo_engage_olympiad from "assets/mo_engage/olympiad";
import OtpVerification from 'molecules/v2/OtpVerification';
import Card from 'atoms/Card.js';
import ModalOtpVerification from 'molecules/Modals/ModalOtpVerification.js';
import SlideTab from 'molecules/v1/SlideTab';
import { LOGIN_STATUS, LOGIN_USER_TYPE } from 'constant/login_signup/index.js';
import FormLogin from 'molecules/v2/FormLogin';


const Login = ({ isOnlyForm = false, screenFor }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUserDeactivated, setIsUserDeactivated] = useState(false);
    const [otp, setOtp] = useState("");
    const [apiData, setApiData] = useState({})
    const [isLoginModal, setIsLoginModal] = useState(false);
    const [otpForLogin, setOtpForLogin] = useState(false);
    const [mobileNo, setMobileNo] = useState("");
    const [isForgatModal, setIsForgatModal] = useState(false);
    const [email, setEmail] = useState("");
    const [isLoginType, setIsLoginType] = useState("");
    const [alertMsg, setAlertMsg] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(screenFor === 'sign-up-practitioner' ? LOGIN_STATUS.PRACTITIONER: LOGIN_STATUS.INDIVIDUAL)
    const { userData, setIsParticipate, isExistingUser, setIsExistingUser, setIsSuccessModalOpen, setOlympiadSuccessMessage, setIsRegistered } = useParticipateOlympiadContext();
    const [isPatient, setIsPatient] = useState(null);

    const router = useRouter();
    const openLoginWithPassword = useRef(null);

    const entity = mo_engage_onboarding.entity;
    const olympiadEntity = mo_engage_olympiad.entity;

    useEffect(() => {
        moengage.trackEvent(entity.site_opened.event_name, {})
    }, [])

    useEffect(() => {
        const url = new URL(window.location.href);
        setTimeout(() => {
            if (url.searchParams.get("login")) {
                setIsLoginModal(true)
            }
        })
    }, [])

    const apiUserData = (apiData) => {
        setApiData(apiData);
    }
    const handleModalStateChange = (bool) => {
        setIsModalOpen(bool)
    }
    const handleLoginScreen = (boll) => {
        setOtpForLogin(false)
        setOtp("")
    }
    const setOtpVal = (data) => {
        setOtp(data)
    }
    const creatUser = async (otp) => {
        setIsLoading(true)
        moengage.trackEvent(entity.onboarding_proceed_with_signup.event_name, {})
        apiData.otp = otp
        apiData.userData.pincode = Number(apiData.userData.pincode)
        apiData.userData.sourceOfLogin = 1;
        apiData.userType = router.query.type == 'individual' ? 1 : null;
        const response = await creatUserApi(apiData)

        if (response.data.message === "Successfully verified Otp!") {
            let user = response.data.entity;
            user.user = Object.assign(user.user, { tbl_doctor_profile: { is_verified: 0, pincode: apiData?.userData?.pincode, title: apiData?.userData?.title } })
            user.isVerifyCheckedKey = user?.user?.tbl_doctor_profile?.is_verified
            setAuth(user);
            if (user.user.firstLogin) {
                const addNirogMoneyResponse = await addNirogMoney({
                    userId: user.user.id,
                    identifier: "",
                    createdBy: user.user.id,
                    contextType: NirogMoneyContext.SIGNUP_CREDIT_NM,
                });
            }
            if (!isExistingUser && userData) {
                let userInterested = {
                    name: userData.name,
                    mobileNumber: userData.mobileNo,
                    userId: user.user.id,
                    createdBy: user.user.id,
                    updatedBy: user.user.id,
                }
                await addInterestedUser(userInterested).then((data) => {
                    if (data?.data?.status === true) {
                        setOlympiadSuccessMessage(data?.data?.message);
                    }
                    if (data?.data?.status === false) {
                        setOlympiadSuccessMessage(data?.data?.message);
                    }
                })
            }

            let redirectUrl = getRedirectUrl();

            if (redirectUrl) {
                setRedirectUrl('');
                router.push(redirectUrl)
            } else router.push(`/profile-verification`);

            userData && setIsSuccessModalOpen(true);
            moengage.trackEvent(olympiadEntity.Olympiad_registration_successfull.event_name, {})

        } else {
            setAlertMsg({ message: response?.data?.message, type: 'error' })
            setIsLoading(false)
        }
    }
    const handleLogin = (data) => {
        setIsLoginModal(data)
    }
    const otpScreenForLogin = (data) => {
        setIsLoginModal(false)
        setOtpForLogin(data)
    }
    const handleOpenForgatModal = () => {
        setIsLoginModal(false);
        setIsForgatModal(true);
    }

    const loginUser = async (data) => {
        let apiData = {}
        if (isLoginType === "mobile") {
            setOtp(otp)
            apiData.mobileNumber = mobileNo;
            apiData.otp = data;
            apiData.country_code = "91";
            apiData.userType = router.query.type == 'individual' ? 1 : null;
            const response = await LoginWithMobileOtp(apiData)
            if (response.data.message === "Successfull User Login") {
                const user = response.data.entity;
                user.isVerifyCheckedKey = user?.user?.tbl_doctor_profile?.is_verified
                setAuth(user);
                if (Moengage) Moengage.add_unique_user_id(user.user.id);
                if (user.user.email && Moengage) Moengage.add_email(user.user.email);
                if (user.user.mobileNumber && Moengage) Moengage.add_mobile(user.user.mobileNumber);
                if (user.user.fullName && Moengage) Moengage.add_user_name(user.user.fullName);

                if (isExistingUser && userData) {
                    let userInterested = {
                        name: userData.name,
                        mobileNumber: userData.mobileNo,
                        userId: user.user.id,
                        createdBy: user.user.id,
                        updatedBy: user.user.id,
                    }
                    await addInterestedUser(userInterested).then((data) => {
                        if (data?.data?.status === true) {
                            setOlympiadSuccessMessage(data?.data?.message);
                        }
                        if (data?.data?.status === false) {
                            setOlympiadSuccessMessage(data?.data?.message);
                            setIsRegistered(true);
                        }
                    })
                }
                let redirectUrl = getRedirectUrl();
                if (redirectUrl) {
                    setRedirectUrl('');
                    router.push(redirectUrl);
                } else router.push('/');
                userData && setIsSuccessModalOpen(true);
                moengage.trackEvent(olympiadEntity.Olympiad_registration_successfull.event_name, {})
            } else {
                setAlertMsg({ message: response?.data?.message, type: 'error' })
            }
        } else {
            apiData.email = email,
                apiData.otp = data,
                apiData.country_code = "91"
            const response = await LoginWithEmailOtp(apiData)
            if (response.data.message === "Successfull User Login") {
                const user = response.data.entity;
                user.isVerifyCheckedKey = user?.user?.tbl_doctor_profile?.is_verified
                setAuth(user);
                if (Moengage) Moengage.add_unique_user_id(user.user.id);
                if (user.user.email && Moengage) Moengage.add_email(user.user.email);
                if (user.user.mobileNumber && Moengage) Moengage.add_mobile(user.user.mobileNumber);
                if (user.user.fullName && Moengage) Moengage.add_user_name(user.user.fullName);
                if (Moengage) Moengage.add_user_attribute("User_type", "web");
                let redirectUrl = getRedirectUrl();
                if (redirectUrl) {
                    setRedirectUrl('');
                    router.push(redirectUrl)
                } else router.back();
            } else {
                setAlertMsg({ message: response?.data?.message, type: 'error' })
            }
        }
    }
    useEffect(() => {
        if (!isExistingUser) {
            setIsLoginModal(false);
            return;
        }
        if (isExistingUser && userData) {
            setIsLoginModal(true);
        }
    }, [isExistingUser]);

    const afterChangePassword = () => {
        setIsForgatModal(false);
        router.push('/login-individual');
    }

    
    

    return (
        <div className='lg:pt-5 md:pt-5'>
            <div className="flex justify-center justify-items-center font-normal w-full max-w-[1300px] mx-auto gap-8 lg:max-h-[580px] md:max-h-[580px] rounded-lg bg-basic-whitePure lg:mt-4 md:mt-4 lg:mb-10 md:mb-10">
                {!isOnlyForm && <SignUpLeftSide />}

                <div className='w-full md:max-w-[500px] '>

                    {!otpForLogin && <FormLogin apiUserData={apiUserData} userData={userData} setIsParticipate={setIsParticipate} setIsModalOpen={setIsModalOpen} handleLogin={handleLogin} isOnlyForm={isOnlyForm} setIsForgatModal={setIsForgatModal} setMobileNo={setMobileNo} setEmail={setEmail} setIsLoginType={setIsLoginType} setIsUserDeactivated={setIsUserDeactivated} otpScreenForLogin={otpScreenForLogin} activeTab={activeTab} isLoading={isLoading} setIsLoading={setIsLoading} setIsPatient={setIsPatient} />}

                    <ModalOtpVerification isLoading={isLoading} handleModalStateChange={handleModalStateChange} isModalOpen={isModalOpen} apiData={apiData} creatUser={creatUser} setOtpVal={setOtpVal} mobileNo={apiData?.mobileNumber} email={apiData?.userData?.email} type="reg" alertMsg={alertMsg} setAlertMsg={setAlertMsg} handlerModalClose={() => setIsModalOpen(false)} />

                    {(!isUserDeactivated && isForgatModal) && <ForgetPassword
                        setMobileNo={setMobileNo}
                        setEmail={setEmail}
                        otpScreenForLogin={otpScreenForLogin}
                        setIsLoginType={setIsLoginType}
                        setIsUserDeactivated={setIsUserDeactivated}
                        isForgatModal={isForgatModal}
                        mobileNo={mobileNo}
                        email={email}
                        setIsForgatModal={setIsForgatModal}
                        alertMsg={alertMsg}
                        setAlertMsg={setAlertMsg}
                        afterChangePassword={afterChangePassword}
                    />}

                    {(!isUserDeactivated && isLoginModal) && <ModalLogin
                        isModalOpen={isLoginModal}
                        handleModalStateChange={setIsLoginModal}
                        setIsUserDeactivated={setIsUserDeactivated}
                        otpScreenForLogin={otpScreenForLogin}
                        setMobileNo={setMobileNo}
                        setEmail={setEmail}
                        setIsLoginType={setIsLoginType}
                        setIsForgatModal={handleOpenForgatModal}
                        handlerModalClose={() => { setIsLoginModal(false); setIsLoginType('') }}
                        openLoginWithPassword={openLoginWithPassword}
                        setIsExistingUser={setIsExistingUser}
                        isExistingUser={isExistingUser}
                        userData={userData}
                        activeTab={activeTab}
                    />}

                    {otpForLogin && <OtpVerification
                        handleLoginScreen={handleLoginScreen}
                        isModalOpen={otpForLogin}
                        loginUser={loginUser}
                        setOtpVal={setOtpVal}
                        mobileNo={mobileNo}
                        email={email}
                        type="login"
                        isLoginType={isLoginType}
                        alertMsg={alertMsg}
                        setAlertMsg={setAlertMsg}
                        handlerModalClose={() => { setOtpForLogin(false); setIsLoginType('') }}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        activeTab={activeTab}
                        isPatient={isPatient}
                    />
                    }
                    {isUserDeactivated && <ModalForDeactivatedUser isUserDeactivated={isUserDeactivated} setIsUserDeactivated={setIsUserDeactivated} />}
                </div>
            </div>
        </div>
    );
}

export default Login;
