import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";

import { yupValidator } from "lib/yup-validator";
import Card from "atoms/Card";
import Button from "atoms/Button";
import Heading from "atoms/Heading";
import Input from "atoms/Input";
import { loginWithMobileAndPassword, sendOtp, sendOtpForLogin, getUserTypesData } from "services/user.service";
import Alert from "molecules/Alert";
import LinkAnchor from "atoms/LinkAnchor";
import Paragraph from "atoms/Paragraph";
import { useRouter } from "next/router";
import { gaLoginPageGetOTP, gaEngageLoginPageSignUpClick, gaIndividualRegisterSubmitOtpClick, gaIndividualLoginGetOtpClick, gaEngageLoginPageSubmitOTP,gaEngageLoginPageGetOTP  } from "@/components/SignUp/LoginSignUpGA";
import { moEngageLoginPageGetOTP, moEngageLoginPageLoginUsingOTP, moEngageLoginPageLoginUsingPassword, moEngageLoginPageSignUpClick, moEngageLoginPageSubmitPassword } from "@/components/SignUp/LoginSignUpMonengage";
import { LOGIN_USER_TYPE } from "constant/login_signup";
import Loading from 'components/Loader/Loading';
import events from "../../assets/mo_engage/login_signup.json"
import ReactSelect from "atoms/forms/ReactSelect";
import { LoginProfession, Profession, USER_TYPES } from "constant";
import { encodedBufferBase64 } from "core";

const FormLogin = ({ className, isModalOpen, isExistingUser, handleModalStateChange, setIsUserDeactivated, otpScreenForLogin, setMobileNo, setEmail, setIsLoginType, setIsForgatModal, handlerModalClose, openLoginWithPassword, setIsExistingUser, userData, isOnlyForm, activeTab, isLoading, setIsLoading, setIsPatient }) => {
    const [otpValue, setOtpValue] = useState("");
    const [alertMsg, setAlertMsg] = useState(null);
    const [isLoginWithPassword, setIsLoginWithPassword] = useState(false)
    const [selectedProfession, setSelectedProfession] = useState(false);
    const [listOfUserTypeData, setListOfUserTypeData] = useState([]);

    const router = useRouter();
    const passwordValidation = isLoginWithPassword ? { password: yup.string().min(6, "Password should be at least 6 characters").required("Password is required") } : {};

    const {
        register,
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
        watch
    } = useForm({
        resolver: yupValidator(
            yup.object().shape({
                mobile_Email: yup.string()
                    .min(10, "Enter your 10 digit mobile number")
                    .test("isNumericString", "Mobile can only have Numbers.", async (value) => /^[0-9]{1,10}$/.test(value))
                    .required("Mobile Number is Required"),
                ...passwordValidation
            })
        )
    })

    const handleChange = (selectedOption) => {
        setSelectedProfession(selectedOption.value);
        if (selectedOption.value === 1) {
            router.push('/login?type=individual');
        } else if (selectedOption.value === 2) {
            router.push('/login?type=practitioner');
        }
    };

    const onSubmit = async (data) => {
        let mobilePattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/
        let emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!data.profession) {
            setAlertMsg({ type: "error", message: "Please select a user type." });
            return;
        }
        if (data?.mobile_Email.match(mobilePattern)) {
            router?.query?.type == 'individual' ? gaIndividualLoginGetOtpClick() : gaLoginPageGetOTP()
            moEngageLoginPageGetOTP();
            setIsLoading(true);
            const response = await sendOtpForLogin({
                mobileNumber: data?.mobile_Email,
                country_code: "91",
                userType: router.query.type == 'individual' ? 1 : null,
            })
            if (response?.data?.message.code === 111 && response?.data?.message.error === 'Your account has been deactivated') {
                setIsUserDeactivated(true);
                handleModalStateChange(false);
                return
            }
            if (response?.data?.message === "Successfully sent Otp!") {
                setMobileNo(response?.data?.entity?.mobileNumber)
                setEmail(response?.data?.entity?.email)
                setIsLoginType('mobile')
                setIsUserDeactivated(false);
                otpScreenForLogin(true);
            } else if (response?.data?.entity?.userExists === false) {
                router.push(`/sign-up?type=${router?.query?.type}&mobile=${encodedBufferBase64(data?.mobile_Email)}&message=${encodedBufferBase64(response?.data?.message)}`)
            } else {
                setAlertMsg({ type: "error", message: response?.data?.message })
            }
            setIsLoading(false);

        }
        else {
            if (data?.mobile_Email) {
                setAlertMsg({ type: "error", message: "Invalid mobile" });
            }
        }
    }

    useEffect(() => {
        setSelectedProfession(router.query.type);
    }, [(router.query.type)]);

    useEffect(() => {
        if (userData && isExistingUser) {
            let data = {
                mobile_Email: userData?.mobileNo?.toString()
            }
            onSubmit(data)
        }
    }, [userData, isExistingUser]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userTypeData = await getUserTypesData();
                if (userTypeData?.status) {
                    const data = [
                        {
                            value: 2,
                            label: userTypeData?.entity
                                ?.slice(1, userTypeData?.entity?.length)
                                ?.map(item => item?.displayName)
                                ?.join("/"),
                            tooltip: "Doctors with B.A.M.S. degrees, Chemists who own Ayurveda Pharmacy, or students pursuing B.A.M.S.",
                        },
                        {
                            value: 1,
                            label: userTypeData?.entity?.[0]?.displayName,
                            tooltip: "Those who are looking for top ayurveda consultations, therapies & medicines.",
                        }
                    ];
                    setListOfUserTypeData(data);
                } else {
                    setListOfUserTypeData(LoginProfession);
                }
            } catch (error) {
                console.error("Error fetching user types data:", error);
                setListOfUserTypeData(LoginProfession);
            }
        };
    
        fetchData();
    }, []);
    
    return (
        <div className={`${isOnlyForm ? '' : ''} flex-shrink-0 w-full relative md:pr-10 lg:pr-10 `}>
            {alertMsg?.message && (
                <Alert type={alertMsg.type} effect="slideDown" resetAlertCallback={() => setAlertMsg(null)}>
                    {alertMsg.message}
                </Alert>
            )}
            {isLoading && <Loading type='layer' />}
            <Card type='card--regular' className="bg-basic-whitePure md:mt-7.5 lg:mb-5  w-full lg:max-w-[500px] shadow-none md:shadow-sm rounded-none md:rounded-2xl-0.5 overflow-hidden">
                <div className="pt-6 pb-6 md:pb-5 px-6 py-5 max-w-[420px] mx-auto">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {alertMsg?.message && <Alert type={alertMsg.type} effect='slideDown' resetAlertCallback={() => setAlertMsg(null)}>{alertMsg.message}</Alert>}
                        <Heading type="h3" className="flex justify-center mb-1 md:mt-5 lg:mt-5 text-center">Welcome to NirogStreet</Heading>

                        <div className="mt-3 lg:mt-2.5 mb-5 sm:mb-8 lg:mb-5 mx-auto">

                            <Paragraph className="text-center mb-8.5 lg:mb-6">Enter your mobile number you used to register on NirogStreet</Paragraph>

                            <Controller
                                control={control}
                                name="profession"
                                render={({ field: { onChange, name, value } }) => (
                                    <ReactSelect
                                        name={name}
                                        placeholder="Select User Type*"
                                        onChange={(data) => {
                                            handleChange(data);
                                            onChange(data?.value);
                                            if (data.value == 1) {
                                                setIsPatient(1);
                                            }
                                        }}
                                        options={listOfUserTypeData}
                                        error={errors.profession}
                                        isTooltip={true}
                                    />
                                )}
                            />

                            <Input
                                className="md:mt-4 lg:mt-4"
                                type="number"
                                isMobileNumber={true} 
                                placeholder='Enter mobile number'
                                register={register}
                                id="mobile_Email"
                                error={errors.mobile_Email}
                                value={watch('mobile_Email')}
                                isRequiredField={true}
                                maxLength={10}
                                setValue={setValue}
                            />

                        </div>


                        <Button
                            className="rounded-lg w-full mx-auto md:mt-8 md:mb-10 lg:mt-8 mb-10"
                            size="medium"
                            variant="Primary"
                            dataAutomation={isLoginWithPassword ? 'proceed-login' : 'get-otp-to-login'}
                        >
                            {isLoginWithPassword ? 'Proceed' : 'Get OTP to Login'}
                        </Button>

                    </form>
                    <div onClick={() => {
                        moEngageLoginPageSignUpClick()
                        gaEngageLoginPageSignUpClick();
                    }} className="py-5 text-center border-t border-gray-200 mb-8">
                        <LinkAnchor href={router?.query?.type === 'individual' ? `/sign-up?type=individual` : `/sign-up?type=practitioner`}>
                            New on NirogStreet? <span className="text-primary1-500 font-bold">Register</span>
                        </LinkAnchor>
                    </div>
                </div>
            </Card>
        </div >
    );
};

export default FormLogin;
